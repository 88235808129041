import React, { useState, useEffect, useCallback } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Divider,
  LinearProgress,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxGroup from 'components/Forms/CheckboxGroup/CheckboxGroupComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';

import { BasicRadioButtonInterface } from 'components/Forms/RadioGroup/RadioGroupComponent';

import { AppStoreInterface } from 'store/app';
import { IFullPlan } from 'types/plans';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import { DependentInterface } from 'types/dependents';
import IBasicOption from 'models/IBasicOption';

import getCurrency from 'helpers/getCurrency';
import {
  MEDICAL_CONDITIONALLY_ENROLLED_WARNING,
  NOT_COVERED_DEPENDENTS_WARNING,
  DOMESTIC_PARTNER_NOT_ELIGIBLE,
  CHILD_AGE_LIMIT_EXCEEDED,
  CHILD_DISABLED,
} from 'constants/messages';
import plansDomain from 'api/domains/plans';
import {
  isChild,
  isChildConditionallyEnrolled,
  isChildCanBeEnrolled,
  isDomesticPartner,
  isDomesticPartnerCanBeEnrolled,
  enrollDependents,
  getDependentLabel,
  isTierNeeded,
} from './logic';
import Over26Modal from './Over26Modal';

import styles from './styles.module.scss';

export interface IDefaultPlanForm {
  userGender?: number | null;
  currentPlan?: IFullPlan;
  enrollment?: IEnrollment;
  dependents: DependentInterface[];
  relationshipTypes: IBasicOption<number>[];
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
  iWantToDecline?: boolean;
  currentPlanType: string;
  setChoosedDependents: (dependents: string[]) => void;
}

interface IDefaultEnrollmentFormBuffer {
  dependentIds: string[];
  contributionsAmount: string;
  status?: string;
}

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
  },
  groupTitle: { fontSize: '1.3rem' },
  subBlock: { margin: '1rem 0' },
  subBlockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginBottom: '10px',
  },
  subBlockContent: {},
  submitBtnContainer: {
    marginTop: '2rem',
  },
  subBlockContr: {
    display: 'flex',
    flexDirection: 'row',
    margin: '30px 0',
  },
  subBlockContribution: {
    marginRight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#9FA2B4',
  },
  contribution: {
    marginTOp: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#00000',
  },
  div: {
    margin: '25px 0',
  },
  addRightMargin: { marginRight: '1rem' },
  contributionLoader: { maxWidth: '200px', marginTop: '1rem' },
});

const DefaultPlanForm: React.FC<IDefaultPlanForm> = (props) => {
  const {
    currentPlan,
    enrollment,
    dependents,
    relationshipTypes,
    updateEnrollment,
    userGender,
    iWantToDecline,
    currentPlanType,
    setChoosedDependents,
  } = props;
  const [dependentIds, setDependentIds] = useState<string[] | null>(null);
  const [status, setStatus] = useState<string | undefined>(
    enrollment?.tier ?? undefined
  );
  const [contributionsAmount, setContributionsAmount] = useState<string>(
    '0.00'
  );
  const [loadNewContributionAmount, setLoadNewContributionAmount] = useState(
    false
  );

  const [over26Modal, setOver26ModalOpen] = useState(false);
  const [buffer, setBuffer] = useState<
    IDefaultEnrollmentFormBuffer | undefined
  >();

  const classes = useStyles();

  const filteredDependents = dependents.filter((dependent) =>
    isChild(dependent)
      ? isChildCanBeEnrolled(
          currentPlanType,
          dependent,
          currentPlan?.tierStructure,
          currentPlan?.dependentMaxAge
        )
      : isDomesticPartner(dependent)
      ? isDomesticPartnerCanBeEnrolled(dependent, currentPlan, userGender)
      : true
  );

  const isAllDependentsCovered = () => {
    return dependentIds?.length === filteredDependents.length;
  };

  const isCheckboxDisabled = useCallback(
    (dependent: DependentInterface): boolean => {
      const partnerCanBeEnrolled = isDomesticPartnerCanBeEnrolled(
        dependent,
        currentPlan,
        userGender
      );

      const childCanBeEnrolled =
        isChildCanBeEnrolled(
          currentPlanType,
          dependent,
          currentPlan?.tierStructure,
          currentPlan?.dependentMaxAge
        ) || Number(dependent?.disabled) === 1;

      return (
        (isDomesticPartner(dependent) && !partnerCanBeEnrolled) ||
        (isChild(dependent) && !childCanBeEnrolled)
      );
    },
    [currentPlan, userGender, currentPlanType]
  );

  const isCanBeChecked = useCallback(
    (dependent: DependentInterface) => {
      return !isCheckboxDisabled(dependent);
    },
    [isCheckboxDisabled]
  );

  const onChangeDependentIds = (ids: string[]) => {
    const newIds: string[] = [];
    for (const id of ids) {
      const foundedDependent = dependents.find(
        (dependent) => dependent.id === id
      );
      if (foundedDependent && isCanBeChecked(foundedDependent))
        newIds.push(foundedDependent.id);
    }
    setDependentIds(newIds);
  };

  const getContributionAmount = async (
    planId: string,
    dependents?: string[],
    status?: number
  ) => {
    try {
      setLoadNewContributionAmount(true);
      setChoosedDependents(dependents ?? []);
      const { data } = await plansDomain.predictContributionAmount(
        planId,
        dependents,
        tierStatus().length === 0 ? null : status
      );
      setContributionsAmount(data.amount ? data.amount.toString() : '0.00');
    } catch (e) {
      console.error(e);
    } finally {
      setLoadNewContributionAmount(false);
    }
  };

  const onSubmit = async () => {
    if (currentPlanType && currentPlan) {
      await updateEnrollment(currentPlanType, {
        dependents: enrollDependents(
          currentPlanType,
          currentPlan?.tierStructure
        )
          ? dependentIds ?? []
          : [],
        planId: currentPlan.id,
        contributionAmount: contributionsAmount,
        tier: tierStatus().length === 0 ? undefined : status,
      });
    }
  };

  const setDataFromBuffer = useCallback(() => {
    if (buffer) {
      setDependentIds(buffer.dependentIds);
      setContributionsAmount(buffer.contributionsAmount);
      setStatus(buffer?.status);
      setBuffer(undefined);
    }
  }, [buffer]);

  const bufferizeData = useCallback(() => {
    setBuffer({
      dependentIds: dependentIds ?? [],
      contributionsAmount,
      status,
    });
    setDependentIds([]);
    setContributionsAmount('0.00');
    setStatus(undefined);
  }, [
    setBuffer,
    setDependentIds,
    setContributionsAmount,
    setStatus,
    contributionsAmount,
    dependentIds,
    status,
  ]);

  const checkIWantToDecline = useCallback(() => {
    if (iWantToDecline) bufferizeData();
    else setDataFromBuffer();
  }, [iWantToDecline, bufferizeData, setDataFromBuffer]);

  useEffect(() => {
    if (currentPlan && dependentIds) {
      getContributionAmount(currentPlan.id, dependentIds);
    }
    // eslint-disable-next-line
  }, [currentPlan, dependentIds]);

  useEffect(() => {
    if (currentPlan && status && tierStatus()?.length !== 0) {
      getContributionAmount(currentPlan.id, undefined, +status);
    }
    // eslint-disable-next-line
  }, [currentPlan, status]);

  useEffect(() => {
    setContributionsAmount(
      typeof enrollment?.contributionAmount === 'string'
        ? enrollment?.contributionAmount
        : '0.00'
    );
  }, [enrollment]);

  useDeepCompareEffect(() => {
    const ids = enrollment ? enrollment.dependents : [];
    const newIds: string[] = [];
    for (const id of ids) {
      const foundedDependent = dependents.find(
        (dependent) => dependent.id === id
      );
      if (foundedDependent && isCanBeChecked(foundedDependent))
        newIds.push(foundedDependent.id);
    }
    setDependentIds(newIds);
  }, [enrollment, dependents]);

  useEffect(() => {
    const tier = enrollment ? enrollment?.tier : undefined;
    setStatus(tier);
  }, [enrollment]);

  useEffect(() => {
    checkIWantToDecline();
  }, [checkIWantToDecline]);

  const isAllDependentsDisabled =
    iWantToDecline ||
    dependents.every((dependent) => isCheckboxDisabled(dependent));

  const renderAlert = () => {
    const isSomeConditionalyEnrolled = dependents
      .filter(isChild)
      .filter((dependent) => dependentIds?.includes(dependent.id))
      .some((dependent) =>
        isChildConditionallyEnrolled(currentPlanType, dependent)
      );

    if (isAllDependentsDisabled) {
      return null;
    }

    if (isSomeConditionalyEnrolled) {
      return (
        <SimpleAlert
          className={styles.alertModal}
          title={MEDICAL_CONDITIONALLY_ENROLLED_WARNING}
          type="warning"
        >
          <Link
            onClick={() => setOver26ModalOpen(true)}
            className={styles.link}
          >
            View conditions for enrollment
          </Link>
        </SimpleAlert>
      );
    }

    if (!isAllDependentsCovered()) {
      return (
        <SimpleAlert
          className={styles.alertModal}
          title={NOT_COVERED_DEPENDENTS_WARNING}
          type="warning"
        />
      );
    }

    return null;
  };

  const renderAlertDisabled = () => {
    const isChildEnrolledAsDisabled = dependents
      .filter(isChild)
      .filter((dependent) => dependentIds?.includes(dependent.id))
      .some(
        (dependent) =>
          !isChildCanBeEnrolled(
            currentPlanType,
            dependent,
            currentPlan?.tierStructure,
            currentPlan?.dependentMaxAge
          ) && +dependent.disabled === 1
      );

    if (isChildEnrolledAsDisabled) {
      return (
        <SimpleAlert
          title={CHILD_DISABLED}
          className={styles.alertModal}
          type="warning"
        />
      );
    }
  };

  const tierStatus = (): BasicRadioButtonInterface[] => {
    if (isTierNeeded(Number(currentPlan?.planTypeId))) return [];

    switch (Number(currentPlan?.tierStructure)) {
      case 4:
        return [
          {
            label: 'Single',
            value: '1',
          },
          {
            label: 'Husband/Wife',
            value: '2',
          },
          {
            label: 'Parent/Child',
            value: '3',
          },
          {
            label: 'Family',
            value: '4',
          },
        ];
      case 3:
        return [
          {
            label: 'Employee only',
            value: '1',
          },
          {
            label: 'Employee +1',
            value: '2',
          },
          {
            label: 'Employee +2',
            value: '4',
          },
        ];
      case 2:
        return [
          {
            label: 'Single',
            value: '1',
          },
          {
            label: 'Family',
            value: '4',
          },
        ];
      default:
        return [];
    }
  };

  return (
    <>
      {enrollDependents(
        currentPlanType,
        currentPlan?.tierStructure,
        currentPlan?.miniCensus,
        currentPlan?.ageBandedRateType as string
      ) && (
        <>
          <Box className={classes.subBlock}>
            <Typography className={classes.subBlockTitle}>
              Choose Dependent(s) Coverage
            </Typography>
            {dependents && dependents.length ? (
              <Box className={classes.subBlockContent}>
                <CheckboxGroup
                  onChange={onChangeDependentIds}
                  checkboxes={dependents.map((dependent) => {
                    const disabled = isCheckboxDisabled(dependent);
                    const disabledMsg = isChild(dependent)
                      ? CHILD_AGE_LIMIT_EXCEEDED
                      : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                    return {
                      value: dependent.id,
                      name: dependent.id,
                      disabled,
                      label: getDependentLabel(
                        dependent,
                        relationshipTypes,
                        disabled ? disabledMsg : undefined
                      ),
                    };
                  })}
                  selected={dependentIds ?? []}
                  selectAllCheckbox
                  selectAllCheckboxLabel="Enroll dependents"
                  disableAll={isAllDependentsDisabled}
                />
                {renderAlertDisabled()}
                {renderAlert()}
              </Box>
            ) : (
              <Box className={classes.subBlockContent}>
                <Typography>You have no dependents</Typography>
              </Box>
            )}
          </Box>
          <Divider className={classes.div} />
        </>
      )}
      <Box className={classes.subBlockContr}>
        <Typography className={classes.subBlockContribution}>
          Your Contribution
        </Typography>
        <Box className={classes.subBlockContent}>
          {loadNewContributionAmount ? (
            <LinearProgress className={classes.contributionLoader} />
          ) : (
            <Typography className={classes.contribution}>{`${getCurrency(
              contributionsAmount,
              2
            )} per pay period`}</Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.submitBtnContainer}>
        {!iWantToDecline && (
          <SubmitButton
            label={!currentPlan ? 'Please choose plan' : 'Enroll'}
            className={classes.addRightMargin}
            onClick={onSubmit}
            disabled={!currentPlan}
          />
        )}
      </Box>
      <Over26Modal
        isOpen={over26Modal}
        handleClose={() => setOver26ModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state: AppStoreInterface) => ({
  dependents: state.userRegistration.dependents,
  relationshipTypes: state.options.relationshipType,
  userGender: state.userRegistration.profile.gender,
});

export default connect(mapStateToProps)(DefaultPlanForm);
